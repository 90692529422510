import { useAuthenticated } from '@flexera/auth.base';
import { loadingItem, useNavItems } from '@flexera/shell.navigation';
import { Permissions, usePermissionSummary } from '@flexera/lib.permissions';
import { OrgsLoading, useCurrentOrg, useOrgId } from '@flexera/shell.orgs';
import { useEffect } from 'react';
import { t } from 'ttag';
import { InternalAdminIcon } from '../assets';
import * as ids from './menuIds';

export function useNav() {
	const authenticated = useAuthenticated();
	// org comes from state
	const org = useCurrentOrg();
	// orgId comes from URL
	const orgId = useOrgId();
	const { addItems } = useNavItems();
	const capabilityPath = `/orgs/${orgId}/internal-admin/`;

	// Permissions
	const [perms, loading] = usePermissionSummary(
		`/orgs/${orgId}`,
		Permissions.canViewOrgIndex,
		Permissions.commonOrgOwner,
		Permissions.hasInternalAdmin
	);

	useEffect(() => {
		if (!authenticated || !orgId) return;

		if (org === OrgsLoading || loading) {
			return addItems([loadingItem(ids.LOADING)]);
		}

		const commonOrgOwner = perms.get(Permissions.commonOrgOwner);
		const canViewOrgIndex = perms.get(Permissions.canViewOrgIndex);
		const hasInternalAdmin = perms.get(Permissions.hasInternalAdmin);

		if (!hasInternalAdmin) return;

		return addItems(
			[
				(commonOrgOwner || canViewOrgIndex) && {
					id: ids.INTERNAL_ADMIN,
					label: t`Internal Administration`,
					icon: InternalAdminIcon,
					path: `${capabilityPath}`,
					urlMatch: /^\/orgs\/\d+\/internal-admin(\/|$|\?)/,
					priority: 196
				}
			].filter(Boolean)
		);
	}, [org, orgId, authenticated, loading, perms]);
}

import React from 'react';
import { flushSync } from 'react-dom';
import { Link } from 'react-router-dom';
import {
	PillInfo,
	getPillTextFromLabelType,
	NavItemChild,
	MenuItem
} from '@flexera/shell.navigation';
import { t } from 'ttag';
import { Icon, IconMdOpenNewTab, Pill } from '@flexera/ui.component-library';
import { determineLinkTo } from './determineLinkTo';
import { addHighlightAroundSearchTerm } from './addHighlightAroundSearchTerm';
import {
	CategoryList,
	CategoryListDescription,
	FavoriteableListItem,
	GrandChildList,
	Divider
} from '../src/Submenu/Submenu.styled';
import { FavoriteButton } from '../src/Favorites/FavoritesButton/FavoriteButton';

export const getChildItems = (
	item: NavItemChild,
	breadcrumbs: MenuItem[],
	hasPeers: boolean,
	searchWord?: string,
	searchIsActive?: boolean,
	setSearchIsActive?: (val: boolean) => void,
	setIsActive?: (val: boolean) => void,
	setFlyoutOpen?: (val: boolean) => void,
	pathname?: string
): JSX.Element | undefined => {
	if (!item.label || item.hidden) {
		return;
	}

	const showSearchStyle = searchIsActive ? 'active-search-item' : '';

	const handleLinkClick = () => {
		if (setIsActive && setFlyoutOpen) {
			flushSync(() => {
				setIsActive(false);
			});
			flushSync(() => {
				setFlyoutOpen(false);
			});
		}
		if (item.onClick) {
			item.onClick();
		}
		if (setSearchIsActive) {
			flushSync(() => {
				setSearchIsActive(false);
			});
		}
	};

	if (item.category && !item.external) {
		const hasLevelThreePeers = false;
		return (
			<CategoryList
				key={`${item.id}-category`}
				className={`${showSearchStyle} ${
					item?.hasCategoryTopDivider ? 'top-divider' : ''
				}`}
			>
				<CategoryListDescription tabIndex={-1}>
					{searchIsActive
						? addHighlightAroundSearchTerm(item.category, searchWord)
						: item.category}
				</CategoryListDescription>
				<GrandChildList role={'menu'} tabIndex={-1}>
					{item.children.map((grandChild) =>
						getChildItems(
							grandChild,
							breadcrumbs,
							hasLevelThreePeers,
							searchWord,
							searchIsActive,
							setSearchIsActive,
							setIsActive,
							setFlyoutOpen,
							pathname
						)
					)}
				</GrandChildList>
			</CategoryList>
		);
	}

	const { id } = item;
	let labelInfo: PillInfo | undefined;

	if (item.labelType) {
		labelInfo = getPillTextFromLabelType(item.labelType);
	}

	// Is Menu Item Selected - General use case
	const pathsMatch = !!(item?.urlMatch && pathname?.match(item.urlMatch));

	// Is Menu Item Selected - PBI Report use case where urlMatch is always different to pathname
	let pageTitleMatches = false;
	if (breadcrumbs?.length > 0) {
		const currentBreadcrumb = breadcrumbs?.at(1);
		pageTitleMatches = currentBreadcrumb?.label === item?.label;
	}

	// Menu item is selected based on either steps listed above
	const isSelected = pathsMatch || pageTitleMatches;

	return (
		<React.Fragment key={id}>
			<FavoriteableListItem
				tabIndex={-1}
				className={`${showSearchStyle} ${
					!item.external && isSelected ? 'selected' : ''
				}`}
				role={'presentation'}
				data-test-id={item.id}
			>
				{item.external && item.path ? (
					<a
						id={
							item.isInFavoriteMenu ? `${item.id}-favorite-link` : `${item.id}-link`
						}
						aria-label={item.label}
						href={determineLinkTo(item)}
						rel={item.path && 'noreferrer'}
						target={item.path && '_blank'}
						onClick={handleLinkClick}
					>
						{searchIsActive
							? addHighlightAroundSearchTerm(item.label, searchWord)
							: item.label}
						<Icon
							color={'#BBB2B0'}
							position={'inherit'}
							src={IconMdOpenNewTab}
							height={'16px'}
							width={'16px'}
							viewBox={'0 0 26 26'}
							verticalAlign={'middle'}
							pl={'4px'}
						/>
					</a>
				) : (
					<Link
						id={
							item.isInFavoriteMenu ? `${item.id}-favorite-link` : `${item.id}-link`
						}
						aria-label={item.label}
						aria-current={!item.external && isSelected ? 'page' : undefined}
						to={determineLinkTo(item)}
						onClick={handleLinkClick}
					>
						{searchIsActive
							? addHighlightAroundSearchTerm(item.label, searchWord)
							: item.label}
						{item.external && (
							<Icon
								color={'#BBB2B0'}
								position={'inherit'}
								src={IconMdOpenNewTab}
								height={'16px'}
								width={'16px'}
								viewBox={'0 0 26 26'}
								verticalAlign={'middle'}
								pl={'4px'}
							/>
						)}
					</Link>
				)}
				{labelInfo?.text && (
					<Pill
						id={
							item.isInFavoriteMenu ? `${item.id}-favorite-pill` : `${item.id}-pill`
						}
						variant={labelInfo.pillVariant}
					>{labelInfo.text}</Pill>
				)}
				<FavoriteButton itemId={id} itemLabel={item.label} />
			</FavoriteableListItem>
			{hasPeers && item.hasDivider && <Divider />}
		</React.Fragment>
	);
};

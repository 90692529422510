import { FC } from 'react';
import { useNav } from './useNav';
import { useRoutes } from './useRoutes';
import { useDataSources } from './data/useDataSources';

export const Workspaces: FC = () => {
	useDataSources();
	useNav();
	useRoutes();
	return null;
};
